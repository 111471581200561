<template>
  <vx-card class="insert-sale-invoice main-box top-zero-radius">

    <div v-if="!isMobile" class="date-items">
      <button class="prev-date-btn" @click="changeTableDay(-1)" :disabled="futureWeeks === 0 && $route.name !== 'insertPastSaleInvoice'">
        {{ $t('sales.invoices.labels.lastWeek') }}
      </button>
      <div class="date-items">
        <div class="date-content">
          <template v-for="i in 7">
            <button class="date-btn"
                    :class="[selectedTableDay > -1 && i === selectedTableDay || selectedTableDay === -1 && i - 1 === weekIndex ? 'active' : '', isToday(i)]"
                    @click="setTableDate(i)"
                    :disabled="getDateBtnDisabled(i)">
              {{ getTableDate($validator('moment.dateWithWeek'), (futureWeeks * 7) + i - weekIndex - 1) }}
              <span v-if="tableDayReserveCount[i - 1] > 0" class="day-reserve-count">
                {{ tableDayReserveCount[i - 1] }}
              </span>
            </button>
          </template>

        </div>
      </div>
      <button class="next-date-btn" @click="changeTableDay(+1)" :disabled="futureWeeks === 0 && $route.name === 'insertPastSaleInvoice'">
        {{ $t('sales.invoices.labels.futureWeek') }}
      </button>
    </div>

    <div class="time-table" :class="isMobile ? 'mobile' : ''">
      <div class="time-table-body">
        <div v-if="timesList.length > 0"
             class="court-row time-label">
          <div class="court-name">{{ $t('sales.invoices.labels.timeCourt') }}</div>
          <select-time v-for="(item, time_index) in timesList[0].times"
                       :time="item.time"
                       :court="item.court"
                       :price="0"
                       :term-id="item.term_id"
                       :is-label="true"
                       :disabled="true"
                       :date="getTableDate()"
                       :selected-times="selectedTimes"/>
        </div>
        <div v-if="courtItem.times && courtItem.times.length > 0"
             class="court-row"
             v-for="(courtItem, court_index) in timesList"
             :key="`court_${court_index}`">
          <div class="court-name">{{ courtItem ? courtItem.court_name : '' }}</div>
          <select-time v-for="(item, time_index) in courtItem.times"
                       :time="item.time"
                       :court="item.court"
                       :price="item.price"
                       :term-id="item.term_id"
                       :current-time="getCurrentHour(item)"
                       :customer="item.customer"
                       :type="item.type"
                       :disabled="getTableItemDisabled(item)"
                       :date="getTableDate()"
                       :selected-times="selectedTimes"
                       @showUser="handleShowProfile(item)"
                       @time:selected="handleTimeSelected($event)"
                       @time:unselected="handleTimeUnselected($event)"/>
        </div>
      </div>
    </div>



    <!-- type selector -->
    <vs-prompt
            color="danger"
            :buttons-hidden="true"
            title=""
            @close="$router.replace({name: 'saleInvoices'})"
            :active.sync="selectInvoiceTypeStatusPrompt">

      <div class="prompt-content p-3">

        <button class="select-type-button"
                @click="handleSelectInvoiceType(1)">
          <custom-icon class="ml-2"
                       icon="BUY"
                       width="50px"
                       height="25px"
                       color="primary"/>

          <vs-spacer/>

          <span class="ml-2">
            {{ $t('sales.invoices.actionTypes.present') }}
          </span>

          <vs-spacer/>
        </button>

        <button class="select-type-button mt-3"
                @click="handleSelectInvoiceType(2)">
          <custom-icon class="ml-2"
                       icon="PHONE"
                       width="50px"
                       height="25px"
                       color="primary"/>

          <vs-spacer/>

          <span class="ml-2">
            {{ $t('sales.invoices.actionTypes.tel') }}
          </span>

          <vs-spacer/>
        </button>
      </div>
    </vs-prompt>
    <!-- /type selector -->


    <!-- show selected times prompt -->
    <vs-prompt
        class="very-big-prompt p-0"
        :buttons-hidden="true"
        title=""
        :active.sync="confirmTimesPromptStatus"
        @close="confirmTimesPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('showPaymentModal')">
              <custom-icon icon="SAVE_PAY" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('sales.invoices.labels.confirm') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="confirmTimesPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <insert-sale-invoice :action-type="newInvoice.actionType" :selected-times="selectedTimes"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- show user details prompt -->
    <vs-prompt
      class="medium-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showUserPromptStatus"
      @close="showUserPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <!--            <div @click="editUserPromptStatus = true">-->
            <!--              <custom-icon icon="EDIT" color="warning"/>-->
            <!--            </div>-->
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('users.user.profile.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showUserPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <user-invoice-profile :user-id="selectedUser.id" :invoice-id="selectedUser.invoiceId"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <vs-button id="setTimeSelected" class="useral-action-button" @click="handleShowReserveConfirm"/>
  </vx-card>
</template>

<script>
import moment from "moment-jalaali";
import axios from "axios";
import {getTimes} from "../../../../../http/requests/times";
import {getCourts} from "../../../../../http/requests/courts";
import {getReservableTimes} from "../../../../../http/requests/sales";
import SelectTime from "../../../../../components/reserveTime/selectTime";
import CustomIcon from "../../../../../components/customIcon/customIcon";
import InsertSaleInvoice from './insertSaleInvoice'
import {showLoading, hideLoading, getTimeFromServer} from "../../../../../assets/js/functions";
import UserInvoiceProfile from "../invoice/saleInvoiceModal";

export default {
  name: "insertTimeSaleInvoice",
  components: {UserInvoiceProfile, CustomIcon, SelectTime, InsertSaleInvoice},metaInfo() {
    return {
      title: this.$route.name === 'insertPastSaleInvoice' ? this.$t('sales.invoices.insert.past.title') : this.$t('sales.invoices.insert.title')
    }
  },
  props: {
    multiSelect: {
      type: Boolean,
      default: () => { return true }
    },
    inModal: {
      type: Boolean,
      default: () => { return false }
    }
  },
  data() {
    return {
      confirmTimesPromptStatus: false,
      showUserPromptStatus: false,
      selectInvoiceTypeStatusPrompt: false,
      isMobile: false,
      futureWeeks: 0,
      weekIndex: 0,
      currentTime: moment(getTimeFromServer()).format(this.$validator('moment.date')),
      getCurrentHourTimer: 0,
      selectedTableDay: -1,
      selectedUser: {},
      timesList: [],
      courtsList: [],
      reservedList: [],
      tableDayReserveCount: [],
      selectedTimes: {},
      reserveTypeStatus: 'date',
      newInvoice: {
        id: {
          value: '',
          isValid: true
        },
        actionType: {
          value: 1,
          label: this.$t('sales.invoices.actionTypes.present')
        },
        status: {},
        user: {
          id: 0,
          name: {
            value: '',
            isValid: true
          }
        },
        date: {
          value: moment(getTimeFromServer()).format(this.$validator('moment.date')),
          isValid: true
        },
        finalPrice: 0,
        totalPrice: 0,
      },
      invoiceProcesses: [
        {
          label: this.$t('sales.invoices.processTypes.preInvoice'),
          value: 1
        },
        {
          label: this.$t('sales.invoices.processTypes.invoice'),
          value: 2
        }
      ],
      actions: [
        {
          toolbar: [
            // {
            //   id: 'setTimeSelected',
            //   icon: 'CHECK',
            //   iconPack: 'useral',
            //   color: 'success',
            //   badge: {
            //     value: 0
            //   }
            // }
          ],
          leftToolbar: [
            {
              id: {name: 'saleInvoices'},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  created() {
    if (!this.inModal) {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.newInvoice.status = this.invoiceProcesses[0]
      }, 100)
    }
    this.currentTime = getTimeFromServer()
    this.isMobile = this.$store.state.windowWidth < this.$store.state.mobileScreen

    this.weekIndex = (moment(this.currentTime).day() + 1) % 7
    this.selectedTableDay = this.weekIndex + 1

    this.getTimes()
    this.getCourts()
    this.getReservableTimes(this.$store.state.helper.calendarDate)
    this.getReserveTableCount()
  },
  mounted () {
    setTimeout(() => {
      showLoading()
    }, 480)

    this.getCurrentHourTimer = setTimeout(() => {
      this.getCurrentHourTimer = 0
    }, 60000 - parseInt(moment(this.currentTime).format(this.$validator('moment.second'))) * 1000)
  },
  methods: {
    getCurrentHour (item) {
      return moment(this.currentTime).format(this.$validator('moment.dateHour')) === `${item.date} ${item.time.start_time.split(':')[0]}`
    },
    isToday (day) {
      return moment(this.currentTime).format(this.$validator('moment.date')) === this.getTableDate(this.$validator('moment.date'), (this.futureWeeks * 7) + day - this.weekIndex - 1) ? 'text-primary' : ''
    },
    getDateBtnDisabled (i) {
      if (this.$route.name === 'insertPastSaleInvoice') {
        return this.futureWeeks === 0 && i - 1 > this.weekIndex
      } else {
        return this.futureWeeks === 0 && i - 1 < this.weekIndex
      }
    },
    getTableItemDisabled (item) {
      if (this.$route.name === 'insertPastSaleInvoice') {
        return parseInt(item.price) === 0 || item.status !== 1 || item.customer || !item.active
      } else {
        return item.status !== 1 || item.customer || !item.active
      }
    },
    getTableDate(formatValue = this.$validator('moment.date'), days = (this.futureWeeks * 7) + this.selectedTableDay - this.weekIndex - 1) {
      if (this.isMobile) {
        return this.$store.state.helper.calendarDate
      } else {
        return moment(this.currentTime).add(days, 'days').format(formatValue)
      }
    },
    setTableDate(index = this.selectedTableDay) {
      this.selectedTableDay = index
      const days = (this.futureWeeks * 7) + index - this.weekIndex - 1
      this.timesList = []
      this.getReservableTimes(days)
    },
    changeTableDay(status) {

      if (this.$route.name === 'insertPastSaleInvoice') {
        if (status < 0) {
          this.futureWeeks -= 1
          this.selectedTableDay = 7
        } else if (this.futureWeeks < 0) {
          this.futureWeeks += 1
          this.selectedTableDay = 1
        }
      } else {
        if (status > 0) {
          this.futureWeeks += 1
          this.selectedTableDay = 1
        } else if (this.futureWeeks > 0) {
          this.futureWeeks -= 1
          this.selectedTableDay = 7
        }
      }
      this.timesList = []
      this.getReserveTableCount()
      this.setTableDate()
    },
    getReservableTimes(days) {
      switch (this.reserveTypeStatus) {
        case 'date':
          this.getReservableTimesBaseDate(days)
          break
      }
    },
    getReservableTimesBaseDate(days) {
      showLoading()
      let date = ''
      if (this.isMobile) {
        date = days
      } else {
        date = this.getTableDate(this.$validator('moment.date'), days)
      }
      let filters = [`date=${date}`]
      if (this.$route.name === 'insertPastSaleInvoice') {
        filters.push('type=2')
      } else {
        filters.push('type=1')
      }
      if (date) {
        getReservableTimes(1, filters).then((response) => {
          const data = response.data.data
          let totalTimes = {}
          data.forEach(time => {
            if (!totalTimes[`court_${time.court.id}`]) {
              let court = time.court
              court.times = []
              totalTimes[`court_${time.court.id}`] = court
            }

            switch (time.status) {
              case 0:
                time.type = 'closed'
                break

              case 1:
                time.type = time.active ? 'selectable' : 'disable'
                break

              case 2:
                time.type = 'reserved'
                break
            }
            totalTimes[`court_${time.court.id}`].times.push(JSON.parse(JSON.stringify(time)))
          })
          this.timesList = Object.values(totalTimes)
          hideLoading()
        })
          .catch(error => {
            hideLoading()
          })
      } else {
        hideLoading()
      }
    },
    getTimes() {
      getTimes().then(response => {
        const times = response.data.data
        times.forEach((time) => {
          this.timesList.push({
            id: time.id,
            startTime: time.start_time.substr(0, 5),
            endTime: time.end_time.substr(0, 5)
          })
        })
      })
    },
    getCourts() {
      getCourts().then((response) => {
        const courts = response.data.data
        courts.forEach((court) => {
          this.courtsList.push({
            id: court.id,
            name: court.name
          })
        })
      })
    },
    handleShowReserveConfirm () {
      if (Object.values(this.selectedTimes).length > 0) {
        this.confirmTimesPromptStatus = true
      } else {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('sales.invoices.validators.noTime'),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          time: 2400
        })
      }
    },
    handleSelectInvoiceType (type) {
      this.newInvoice.actionType = {
        value: type,
        label: type === 1 ? this.$t('sales.invoices.actionTypes.present') : this.$t('sales.invoices.actionTypes.tel')
      }
      this.selectInvoiceTypeStatusPrompt = false
    },
    handleTimeSelected(selectedTime) {
      delete selectedTime.court.times
      this.selectedTimes[`${selectedTime.date}_${selectedTime.time.id}_${selectedTime.court.id}`] = selectedTime
      let actions = JSON.parse(JSON.stringify(this.actions))
      if (Object.values(this.selectedTimes).length > 0) {
        actions[0].toolbar.push({
          id: 'setTimeSelected',
          icon: 'CHECK',
          iconPack: 'useral',
          color: 'success',
          badge: {
            value: 0
          },
          permission: 'invoice.create'
        })
      }
      if (this.multiSelect) {
        if (actions[0].toolbar.length > 0) {
          actions[0].toolbar[0].badge.value = Object.values(this.selectedTimes).length
        }
        setTimeout(() => {
          this.$store.dispatch('updateNavbarActions', actions[0])
        }, 100)
        this.getReserveTableCount()
      } else {
        this.$emit('selected', this.selectedTimes)
      }
    },
    getReserveTableCount (initialDate) {
      const selectedTimes = Object.keys(this.selectedTimes)
      let timeCounts = []
      for (let i = 1; i <= 7; i++) {
        let count = 0
        let date = ''
        if (this.isMobile) {
          date = initialDate
        } else {
          date = this.getTableDate(this.$validator('moment.date'), (this.futureWeeks * 7) + i - this.weekIndex - 1)
        }
        selectedTimes.forEach((time) => {
          if (time.split('_')[0] === date) {
            count++
          }
        })
        timeCounts.push(count)
      }
      this.tableDayReserveCount = timeCounts
    },
    handleTimeUnselected(unselectedTime) {
      delete this.selectedTimes[`${unselectedTime.date}_${unselectedTime.time.id}_${unselectedTime.court.id}`]
      setTimeout(() => {
        let actions = JSON.parse(JSON.stringify(this.actions))
        if (Object.values(this.selectedTimes).length === 0) {
          this.actions[0].toolbar.splice(0, 1)
          actions[0].toolbar.splice(0, 1)
        } else {
          actions[0].toolbar.push({
            id: 'setTimeSelected',
            icon: 'CHECK',
            iconPack: 'useral',
            color: 'success',
            badge: {
              value: 0
            },
            permission: 'invoice.create'
          })
          actions[0].toolbar[0].badge.value = Object.values(this.selectedTimes).length
        }
        this.$store.dispatch('updateNavbarActions', actions[0])
      }, 100)
      this.getReserveTableCount()
    },
    handleShowProfile(item) {
      if (item.customer) {
        this.selectedUser = item.customer
        this.selectedUser.invoiceId = item.invoice_id
        this.showUserPromptStatus = true
        // let routeData = this.$router.resolve({name: 'saleInvoice', params: {id: item.invoice_id}})
        // window.open(routeData.href, '_blank')
      }
    },
    handleClick(id) {
      document.getElementById(id).click()
    },
  },

  watch: {
    getCurrentHourTimer: {
      handler (val) {
        if (val === 0) {
          this.$forceUpdate()
          this.getCurrentHourTimer = setTimeout(() => {
            this.getCurrentHourTimer = 0
          }, 60000)
        }
      }
    },
    '$store.state.helper.calendarDate': {
      handler (val) {
        this.isMobile = this.$store.state.windowWidth < this.$store.state.mobileScreen
        this.timesList = []
        this.getReservableTimes(val)
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../../../../assets/scss/vuexy/variables";

.insert-sale-invoice {

  .vx-card__collapsible-content {
    height: 100%;

    .vx-card__body {
      padding: 0 !important;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .date-items {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border: 1px solid #f0f0f0;

    .prev-date-btn,
    .next-date-btn {
      padding: 5px 20px;
      margin: 10px;
      background-color: #f8f8f8;
      border: 1px solid #cecece;
      border-radius: 0.5rem;

      &:hover:not([disabled]) {
        cursor: pointer;
        background-color: #f0f0f0;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    .date-items {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      overflow: auto;
      .date-content {
        display: flex;
        text-align: center;
        overflow-x: auto;
        padding: 5px;
        padding-top: 10px;

        &::-webkit-scrollbar {
          display: block;
          background: rgba(255, 255, 255, 0.50);
          height: 10px;
          width: 10px;
          border-radius: 0.5rem;
        }

        &::-webkit-scrollbar-thumb {
          display: block;
          background: rgba(206, 206, 206, 1);
          height: 6px;
          border-radius: .5rem;
        }

        .date-btn {
          position: relative;
          padding: 5px 20px;
          margin: 0 5px;
          background-color: #f8f8f8;
          border: 1px solid #cecece;
          border-radius: 0.5rem;
          white-space: nowrap;

          &:hover:not([disabled]) {
            cursor: pointer;
            background-color: #f0f0f0;
          }

          &.active {
            background-color: $sidebar-semi-bg;
            color: #ffffff;

            &:hover {
              background-color: #1b2027;
            }
          }

          &:disabled {
            cursor: not-allowed;
          }

          .day-reserve-count {
            position: absolute;
            top: -10px;
            right: calc(50% - 8px);
            height: 16px;
            width: 16px;
            background-color: $success;
            color: #ffffff;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .time-table {
    position: relative;
    flex: 1;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*align-content: start;*/
    /*flex-wrap: wrap;*/
    padding: 10px;
    /*padding-left: 170px;*/
    align-self: flex-start;
    justify-self: flex-start;
    direction: ltr;



    &::-webkit-scrollbar {
      display: block;
      background: rgba(255, 255, 255, 0.50);
      height: 10px;
      width: 10px;
      border-radius: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      display: block;
      background: rgba(206, 206, 206, 1);
      height: 6px;
      border-radius: .5rem;
    }

    &.mobile {
      height: calc(100vh - 175px);
    }

    .time-table-body {
      display: flex;
      -webkit-box-orient: revert;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row;
      flex-direction: row;
      position: relative;
      align-self: flex-start;
      justify-self: flex-start;
    }

    .court-row {
      position: relative;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      width: 150px;

      @media screen and (max-width: 768px) {
        width: 100px;
      }

      .court-name {
        width: 100%;
        line-height: 60px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $sidebar-semi-bg;
        /*border-radius: 0.5rem 0.5rem 0 0;*/
        position: relative;
        align-self: flex-start;
        color: #ffffff;
        font-weight: 500;
        border-right: 1px solid #cdcdcd;
        border-bottom: 1px solid #cdcdcd;
        z-index: 90;


        @supports (position: sticky) {
          position: -webkit-sticky;
          position: sticky;
          top: -11px;
        }
      }

      &:first-child {
        .court-name {
          border-radius: 0.5rem 0 0 0;
        }
      }

      &:last-child {
        .court-name {
          border-radius: 0 0.5rem 0 0;
          border-right: none;
        }

        .select-hour-box:last-child {
          button {
            border-radius: 0 0 0.5rem 0;
          }
        }
      }
    }

    .time-label {
      position: relative;
      /*position: -webkit-sticky;*/
      /*background-color: #f8f8f8;*/
      /*align-self: flex-start;*/
      /*justify-self: flex-start;*/
      z-index: 100;
      width: 90px;

      @supports (position: sticky) {
        position: -webkit-sticky;
        position: sticky;
        left: -10px;
      }

      @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        position: -webkit-sticky;
        position: sticky;
        left: 0;
      }

      .court-name {
        z-index: 101;
      }

      .select-hour-box {
        position: relative;
        height: 60px;
        left: 0;
        right: 0;

        .is-label {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $sidebar-semi-bg;
          color: #ffffff;
          z-index: 100;
        }
      }

      .select-hour-box:last-child {
        .is-label {
          border-radius: 0 0 0 0.5rem;
        }
      }
    }
  }
}

.action-buttons {
  display: flex;

  button {
    flex: 1;
    line-height: 40px;
    border-radius: .5rem;
    border: none;
    color: #ffffff;
    transition: all .3s ease;
    cursor: pointer;

    &.payment {
      margin-right: 10px;
      background: #33b000cf;

      &:hover {
        background: #33b000;
      }
    }

    &.cancel {
      background: #b00000af;

      &:hover {
        background: #b00000;
      }
    }
  }
}
</style>
